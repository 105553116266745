@import "src/assets/styles/variables";

.linesWrap {
  padding: 40px 0;
}

.marqueWrap {
  white-space: nowrap;
}

.lineText {
  display: inline-flex;

  font-size: 24px;
  font-weight: 900;
  line-height: 120%;
  font-family: "Druk Text Wide Cyr", "Roboto", sans-serif;
  letter-spacing: 1.38px;
  text-transform: uppercase;
}

.lineText2 {
  background: -webkit-linear-gradient(0deg, #170c20 100%, #170c20 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
  color: #ffffff;
}

.textImgWrap {
  margin: 0 auto;
  padding: 0 108px;
  padding-top: 40px;

  max-width: 1251px;
}

.textImg {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.linesBg {
  position: relative;
}

.line1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  display: block;
  width: 95%;
  height: 1px;

  background-color: #c0bfc6;
}

.line2,
.line3,
.line4,
.line5 {
  position: absolute;
  top: 0;

  display: block;
  width: 1px;
  height: 100%;

  background-color: #c0bfc6;

  z-index: -1;
}

.line2 {
  left: 13%;
}

.line3 {
  left: 49%;
}

.line4 {
  left: 71%;
}

.line5 {
  left: 87%;
}

@media (max-width: $mobileWidth) {
  .line2,
  .line3,
  .line4,
  .line5 {
    display: none;
  }
}

@media (max-width: 576px) {
  .linesWrap {
    padding: 24px 0;
  }
}
