@font-face {
  font-family: "Closer";
  src: url("CloserText-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Closer";
  src: url("CloserText-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Closer";
  src: url("CloserText-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Druk Text Wide Cyr";
  src: url("Druk-Text-Wide-Cyr-H.woff") format("woff");
  font-weight: 900;
  font-display: swap;
}
